@import url(https://fonts.googleapis.com/css?family=Josefin+Sans);
@import url(https://fonts.googleapis.com/css?family=Encode+Sans+Expanded);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Encode Sans Expanded", sans-serif;
}

.logo-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.logo-brand {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 28px;
  letter-spacing: 1.2px;
  color: #ffffff;
  text-transform: uppercase;
}

.logo-separator {
  height: 2px;
  width: 100%;
  background-color: #FFD700;
  margin: 4px 0;
}

.logo-tagline {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 2px;
  color: #ffffff;
  text-transform: uppercase;
}

.logo-text {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  letter-spacing: -0.5px;
  position: relative;
  display: flex;
  align-items: center;
}

.angle-bracket {
  font-family: 'Space Mono', monospace;
  font-weight: 700;
  color: #FFD700 !important;
  position: relative;
}

.angle-bracket:first-child {
  margin-right: 3px;
}

.angle-bracket:last-child {
  margin-left: 3px;
}

.center-align {
  text-align: center;
}

.black-bg {
  background: black;

}

.white-bg {
  background: white;

}

.primary-bg {
  background: #FFD700;

}

.h1-hero {
  color: #fff;
  font-size: 48px;
  text-align: center;
  font-weight: bold;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }

}

.hero-text {
  color: #fff;
  font-size: 24px;
  text-align: center;

}

.hero-description {
  color: #fff;
  font-size: 22px;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 16px;
  max-width: 700px;
  line-height: 1.5;
  font-weight: 400;
  
  @media screen and (max-width: 768px) {
    font-size: 18px;
    max-width: 90%;
  }
  
  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
}

.img-wrap {
  height: 100%;
  max-width: 700px;
  margin: auto;
  padding: 20px;
  position: relative;
}

.fit-img {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.fit-img:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
}

.expand-row {
  width: 100%;
}

.white-text {
  color: white;
}

.black-text {
  color: black;
}

.gray-text {
  color: gray;
}

.pl {
  padding-left: 20px;
}

.pr {
  padding-right: 20px;
}

.logo-dot {
  display: inline-block;
  width: 5px;
  height: 5px;
  background: #FFD700;
  border-radius: 50%;
  margin-left: 4px;
  position: relative;
}

.nav-active {
  color: #FFD700 !important;
}
